import React from "react"
import Layout from "../components/layout"
// import "react-responsive-carousel/lib/styles/carousel.min.css"
import TechnicalData from "../components/product_tech_data"
import ProductDetail from "../components/product_detail"
import sky_lift from "../../public/static/images/products/sky_lift.jpg"

function SkyLift() {

  const prodDetail = {
    "name": "SKY LIFT / HYDRAULICS ACCESS PLATFORM",
    "detail": {
      "about" : ["Sky lift is an also known as automatic lifting machine for maintenance purposes in different sections such as municipalities works, Highways, Building Glass Cleaning & Constructions etc."],
      "advantages": [
        "It’s unique, easy operational, low maintenance & full Proof system helps in keeping all operations safe.",
        "Safe load Handling capacity upto 250 Kg of FRP Bucket & Insulation of cage to minimize the accident and make the operation easy.",
        "Heavy duty cylinders to carry bucket that is attached with Booms on a required height safely in very less time.",
        "Optimum Lifting & Lowering ability through heavy duty hydraulic cylinders, hoses, Motor, Pump and safety devices like loading valve, pressure valve etc.",
        "Hydraulically arrangement for system with the help of Control level for minimize the lifting time.",
        "Arrangement for stabilizer of vehicle for better Strength at the time of operation."
      ]
    },
    "tech_data": {
      "column1": [
        "Code",
        "Chassis",
        "Size Available",
        "Sheet Material",
        "Thickness",
        "Bucket",
        "Hydraulic Cylinder",
        "Hydraulic Pump",
        "Hydraulic Motor",
        "Hydraulic Hoses",
        "Power Take Off",
        "Hydraulic Tank",
        "Slewing"
      ],
      "column2": [
        "IESL",
        "TATA / LAYLAND / EICHER / MAHINDAR etc.",
        "7 Mtr, 9 Mtr, 11 Mtr, 14 Mtr, 16 Mtr Or Customized.",
        "Mild Steel IS:2062 Grade-A",
        "3mm 4mm 5mm 6mm etc.",
        "600 / 1100 Volts Insulated Material With Single Person Or Two Person.",
        "Dental / Hitech / IEPL",
        "20-80 LPM Gear Pump / Vane Pump",
        "50-200 CC High Pressure Hoses",
        "ISO Reputed Make",
        "Power Take Off",
        "20 to 80 Litres",
        "360º"
      ]
    }
  }

  return (
    <Layout className="semi-automatic">
      <ProductDetail name={prodDetail.name} detail={prodDetail.detail} image={sky_lift} />

      {prodDetail.tech_data !== undefined ? <TechnicalData techData={prodDetail.tech_data} /> : <></>}
    </Layout>
  )
}

export default SkyLift
